
import s from './Header.module.scss'
import {useCourierStore} from "../../store/courierStore";
import {Link} from "react-router-dom";
import {Navigation} from "./navigation/Navigation";

export const Header = () => {
    const {userdata, isLoadCheckAuthAPI} = useCourierStore()

    return (
        <div className={s.wrapper}>
            <Link to={'/'} className={s.link}>Курьеры</Link>

            <Navigation />
        </div>
    )
}