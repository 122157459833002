
import s from './Login.module.scss'
import {useState} from "react";
import {useCourierStore} from "../../store/courierStore";

export const Login = () => {
    const {LoginAPI, errorLoginMessage} = useCourierStore()

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    return (
        <div className={s.wrapper}>
            <div className={s.window}>
                <h3>Авторизация</h3>
                <input type="text" placeholder={'Логин'}
                       onChange={(e) => setLogin(e.target.value)}
                       value={login}
                />
                <input type="password" placeholder={'Пароль'}
                       onChange={(e) => setPassword(e.target.value)}
                       value={password}
                />
                <span>{errorLoginMessage}</span>
                <button onClick={() => LoginAPI(login, password)}>Войти</button>
            </div>
        </div>
    )
}