import './App.css';
import {useEffect, useState} from "react";

import {Route, Routes} from "react-router-dom";

import {ActiveOrdersPage} from "./pages/ActiveOrderPage/ActiveOrdersPage";
import {ProfilePage} from "./pages/Profile/ProfilePage";

import {OrderPopup} from "./components/orderPopup/OrderPopup";
import {Login} from "./components/login/Login";
import {Header} from "./components/header/Header";

import {useCourierStore} from "./store/courierStore";
import {useOrderStore} from "./store/orderStore";

import logo from './logocircle.svg'


function App() {
    const {CheckAuthAPI, isLogin, isLoadCheckAuthAPI} = useCourierStore()
    const {isOpenOrderPopup, setIsOpenOrderPopup} = useOrderStore()


    useEffect(() => {
        CheckAuthAPI()
    }, [])


    if (isLoadCheckAuthAPI) {
    return (
        <div className='loading'>
          <span>Курьеры</span>
          <img src={logo} alt=""/>
        </div>
    )
    }

    return (
    <div className="App">
      {!isLogin ? <Login /> :
          <>
            <Header />

            <main className='wrapper'>
              <Routes>
                {/*<Route path="/manager/completedOrders" element={<CompletedOrders />} />*/}
                <Route path="/" element={<ActiveOrdersPage />} />
                <Route path="/profile" element={<ProfilePage />} />
              </Routes>
            </main>

            {isOpenOrderPopup &&  <OrderPopup setIsOpenPopupOrder={setIsOpenOrderPopup}/>}

          </>
      }
    </div>
    );
}

export default App;
