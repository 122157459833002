import {url} from './../config'
import axios from "axios";

axios.defaults.withCredentials = true

export function CheckAuth (){
    return axios.get(`${url}/couriers/checkauth`, {
    })
}

export function GetOrders (){
    return axios.get(`${url}/couriers/getorders`)
}

export function Login (username, password){
    return axios.post(`${url}/couriers/login?username=${username}&password=${password}`)
}

export function Exit (){
    return axios.post(`${url}/couriers/exit`)
}

export async function SuccessCalcelOrder (orderid){
    return axios.post(`${url}/setlogstatus?orderid=${orderid}&statusid=4`)
}