import { create } from 'zustand'
import {devtools} from 'zustand/middleware'
import {CheckAuth, Exit, GetOrders, Login, SuccessCalcelOrder} from "./courierAPI";

export const useCourierStore = create(devtools((set) => (
            {
                isLogin: false,
                userdata: {},
                orders: [],
                errorLoginMessage: '',
                LoginAPI: (username, password) => {
                    Login(username, password).then(function (response) {
                        set({isLogin: true})
                        set({errorLoginMessage: ''})
                        set({userdata: response.data})
                    }).catch(function (error) {
                        set({errorLoginMessage: error.response.data.detail})
                        set({isLogin: false})
                    })
                },
                isLoadCheckAuthAPI: false,
                CheckAuthAPI: async (data) => {
                    set({isLoadCheckAuthAPI: true})
                    await CheckAuth().then(function (response) {
                        set({isLogin: true})
                        set({userdata: response.data})
                    }).catch(function (error) {
                        set({isLogin: false})
                    })
                    set({isLoadCheckAuthAPI: false})
                    set({ordersFilter: data})
                },

                GetOrdersAPI: () => {
                    GetOrders().then(function (response) {
                        set({orders: response.data.orders})
                    }).catch(function (error) {
                        set({orders: []})
                    })
                },

                ExitFromAccountAPI: () => {
                    Exit().then(function (response) {
                        set({orders: []})
                        set({isLogin: false})
                    }).catch(function (error) {
                        set({orders: []})
                        set({isLogin: false})
                    })
                },

                SuccessCalcelOrdertAPI: async (orderid) => {
                    await SuccessCalcelOrder(orderid).then(function (response) {
                        GetOrdersAPI()
                    }).catch(function (error) {
                        alert('Не удалось завершить заказ')
                    })
                },
            }
        ),
        {
            name: "courier-storage"
        }
    )
)


const {GetOrdersAPI} = useCourierStore.getState();