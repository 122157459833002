import s from './ActiveOrdersPage.module.scss'
import {useEffect} from "react";
import {useCourierStore} from "../../store/courierStore";
import ComputeTime from "../../hooks/computeTime";
import {useOrderStore} from "../../store/orderStore";


export const ActiveOrdersPage = () => {
    const {orders, GetOrdersAPI, } = useCourierStore()

    useEffect(() => {
        GetOrdersAPI()
    }, [])

    return (
        <main className={s.wrapper}>
            <h3>Активные заказы</h3>
            {
                orders.map((order) => {
                    return <Order order={order}
                                  key={order.id}
                    />
                })
            }

        </main>
    )
}

const Order = ({order}) => {
    var street = `${order.items.address.street}${order.items.address.entrance && `, подъезд ${order.items.address.entrance}`}${order.items.address.floor && `, этаж ${order.items.address.floor}`}${order.items.address.apartment && `, кв ${order.items.address.apartment}`}`

    const {setIsOpenOrderPopup, setPopupOrderData} = useOrderStore()

    return (
        <div className={s.item}
             onClick={() => {
                 setIsOpenOrderPopup(true)
                 setPopupOrderData(order)
             }}>
            <span className={s.light}>Заказ: № {order.id}</span>
            {order.items.comment && <span className={s.green}>Комментарий: {order.items.comment}</span>}

            <span className={s.blue}>
                {street}
                <button className={s.button}
                        onClick={(e) => {
                            copyTextToClipboard(street)
                            e.stopPropagation()
                        }
                        }>Copy</button>
            </span>
            <span className={s.light}>
                +7{order.user.number}{order.user.name && `, ${order.user.name}`}
                <button className={s.button}
                        onClick={(e) => {
                            copyTextToClipboard(`+7${order.user.number}`)
                            e.stopPropagation()
                        }
                        }>Copy</button>
            </span>
            <span style={{color: 'var(--red)'}}>{order.items.total_sum}р</span>
            <span>{order.items.paytype}</span>
            <span>{ComputeTime(order.created_at)}</span>
        </div>
    )

}

const copyTextToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
};