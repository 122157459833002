import { create } from 'zustand'
import {devtools} from 'zustand/middleware'

export const useOrderStore = create(devtools((set) => (
            {
                isOpenOrderPopup: false,
                setIsOpenOrderPopup: (bool) => {
                    set({isOpenOrderPopup: bool})
                },

                popupOrderData: {},
                setPopupOrderData: (order) => {
                    set({popupOrderData: order})
                },
            }
        ),
        {
            name: "order-storage"
        }
    )
)


