
import s from './Navigation.module.scss'
import {Link} from "react-router-dom";
import {useState} from "react";

export const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false)

    if (!isOpen) {
        return (
            <div className={s.icon} onClick={() => setIsOpen(true)}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        )
    }

    return (
        <div className={s.wrapper}>
            <div className={s.link}>
                <Link to={'/'} onClick={() => setIsOpen(false)}>Главная</Link>
                <div className={s.icon} onClick={() => setIsOpen(false)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className={s.link}>
                <Link to={'/profile'} onClick={() => setIsOpen(false)}>Профиль</Link>
            </div>
        </div>
    )
}