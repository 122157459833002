
import s from './OrderPopup.module.scss'
import {useOrderStore} from "../../store/orderStore";
import {useState} from "react";
import {useCourierStore} from "../../store/courierStore";


export const OrderPopup = () => {
    const {setIsOpenOrderPopup, popupOrderData} = useOrderStore()



    return (
        <div className={s.wrapper}
             onClick={() => setIsOpenOrderPopup(false)}
        >
            <div className={s.content} onClick={(e) => e.stopPropagation()}>
                <div className={s.info}>
                    <h2 className={s.title}>Заказ {popupOrderData.id}</h2>
                </div>
                <ToAssembleOrder order={popupOrderData}/>
            </div>
        </div>
    )
}

const ToAssembleOrder = ({order}) => {
    const [isOpenConfirm, setIsOpenConfirm] = useState(false)
    return (
        <>
        {order.items.comment && <div className={s.infoRow}>
                <span>Комментарий</span>: {order.items.comment}
            </div>}

            <div className={s.infoRow}>
                <div className={s.column}>
                    <span style={{marginBottom: '5px'}}>Состав:</span>
                    {order.items.promocode.type === 1 && <div className={s.row}>
                        <div className={s.count}>1</div> {order.items.promocode.item.title}, {order.items.promocode.item.size}{order.items.promocode.item.unit}
                    </div>}
                    {order.items.items.map((i) => {
                        return  <div className={s.row}>
                            <div className={s.count}>{i.quantity}</div> {i.title}, {i.size}{i.unit}
                                </div>
                    })}
                </div>
            </div>

            <div className={s.buttons}>
                <button
                    onClick={() => setIsOpenConfirm(true)}
                >Доставлен
                </button>
            </div>

            {isOpenConfirm && <ConfirmCalcelOrder id={order.id} closePopup={() => setIsOpenConfirm(false)}/>}
        </>
    )
}

const ConfirmCalcelOrder = ({id, closePopup}) => {
    const {SuccessCalcelOrdertAPI} = useCourierStore()
    const {setIsOpenOrderPopup} = useOrderStore()

    return (
        <div
            className={s.wrapper}
            onClick={(e) => closePopup()}
        >
            <div className={s.content} onClick={(e) => e.stopPropagation()}>
                <div className={s.buttons}>
                    <button
                        className={s.disactive}
                        onClick={() => closePopup()}
                    >Отмена
                    </button>
                    <button
                        onClick={async () => {
                            await SuccessCalcelOrdertAPI(id)
                            closePopup()
                            setIsOpenOrderPopup(false)
                    }}
                    >Подтвердить
                    </button>
                </div>
            </div>
        </div>
    )
}

