import s from './ProfilePage.module.scss'
import {useEffect} from "react";
import {useCourierStore} from "../../store/courierStore";
import {useOrderStore} from "../../store/orderStore";


export const ProfilePage = () => {
    const {userdata, ExitFromAccountAPI} = useCourierStore()


    return (
        <main className={s.wrapper}>
            <h3>Кабинет</h3>

            <div className={'infoRow'}>
                {userdata.name} {userdata.surname}
            </div>
            <div className={'infoRow'}>
                +{userdata.number}
            </div>

            <button
                className={s.button}
                onClick={() => ExitFromAccountAPI()}
            >Выход</button>
        </main>
    )
}
